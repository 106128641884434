import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        
        <div className="addbg2">
          <div className="wrapper">
            <header>
              <h1 className="logo">
                <a href="/">Neutrogena</a>
              </h1>
              <nav>
                <a href="/produkty/" className="link">
                  Produkty
                </a>
                <span>| </span>
                <a href="/neutrogena/o-nas/" className="link">
                  NEUTROGENA<sup>®</sup>
                </a>
                <span>| </span>
                <a href="/budte-krasna/" className="link">
                  Buďte krásná
                </a>
                <span>| </span>
                <a href="/nordic-berry/" className="link">
                  Nordic Berry
                </a>
                <span />
                <span>|</span>
                <a href="/plet-s-nedokonalostmi/">Pleť s nedokonalostmi</a>
                <span />
              </nav>
            </header>
            <div className="content">
              <div className="breadcrumb">
                <a href="/">
                  NEUTROGENA<sup>®</sup>
                </a>
                <span>&gt;</span>
                <a href="/produkty/">Popis</a>
                <span>&gt;</span>
                <a href="/product/61/#" className="blue">
                  {" "}
                  <p>
                    NEUTROGENA<sup>®</sup> Norská receptura Ultra výživný
                    intenzivní balzám
                  </p>{" "}
                </a>
              </div>
              <div className="singleproduct">
                <div className="prodimgcont">
                  <div className="bot">
                    <img
                      src="/assets/Uploads/ultra-nourishing-BALM-200ML2.png"
                      alt="ultra nourishing BALM 200ML2"
                    />
                  </div>
                </div>
                <div className="info">
                  <h2>
                    {" "}
                    <p>
                      NEUTROGENA<sup>®</sup> Norská receptura Ultra výživný
                      intenzivní balzám
                    </p>
                  </h2>
                  <dl>
                    <dt>Popis:</dt>
                    <dd>
                      <p>
                        <span>
                          Intenzivně vyživuje a přináší tak okamžitou úlevu.
                          Zanechává vaši pokožku chráněnou a zdravě vypadající
                          po celý den. Je prokázáno, že Norská receptura tohoto
                          produktu přináší napjaté a suché pokožce okamžitou
                          úlevu. Je obohacena vitamínem E. Pomáhá chránit
                          pokožku před vysušením, které může být způsobeno
                          negativními externími{" "}
                        </span>
                        <span>vlivy.</span>
                        <span> Pro suchou a velmi suchou pokožku.</span>
                      </p>
                    </dd>
                  </dl>
                  <dl>
                    <dt>Použití:</dt>
                    <dd>
                      Nanášejte jednou či dvakrát denně na celé tělo, obličej a
                      ruce.
                    </dd>
                  </dl>
                  <dl className="last">
                    <dt>Balení:</dt>
                    <dd>200 ml</dd>
                  </dl>
                </div>
                <div id="fb_22"></div>
              </div>
              <div className="products">
                <div className="row">
                  <div className="rowtitle">Zobrazit související produkty:</div>
                  <a
                    className="product group1_1 group2_1 group3_1"
                    href="/product/101/"
                  >
                    <img
                      src="/assets/Uploads/NeutrogenaHydroBoost-BodyGelSpray-200ml.png"
                      alt="NeutrogenaHydroBoost BodyGelSpray 200ml"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>® </sup>Hydro&nbsp;Boost<sup>®</sup>{" "}
                        Tělový hydratační sprej
                      </p>
                    </span>
                    <span className="button">Podrobnosti</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_1 group2_1 group3_1"
                    href="/product/36/"
                  >
                    <img
                      src="/assets/Uploads/NTG-lipcare-NordicBerry-48g-v2.png"
                      alt="NTG lipcare NordicBerry 48g v2"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> Norská receptura Výživný balzám
                        na rty &nbsp;Nordic Berry
                      </p>
                    </span>
                    <span className="button">Podrobnosti</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_1 group2_1 group3_1"
                    href="/product/55/"
                  >
                    <img
                      src="/assets/Uploads/NTG-Lipp-LSF4-48g-bezblistru2.png"
                      alt="NTG Lipp LSF4 48g bezblistru2"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> Norská receptura Balzám na rty
                        SPF 4
                      </p>
                    </span>
                    <span className="button">Podrobnosti</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_1 group2_1 group3_1"
                    href="/product/56/"
                  >
                    <img
                      src="/assets/Uploads/Nutrogena-LSF-20-SUN.png"
                      alt="Nutrogena LSF 20 SUN"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> Norská receptura Balzám na rty
                        SPF 20
                      </p>
                    </span>
                    <span className="button">Podrobnosti</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_1 group2_1 group3_1"
                    href="/product/71/"
                  >
                    <img
                      src="/assets/Uploads/NEUTROGENA-Visibly-Clear-emulze-grapefruit-200ml.jpg"
                      alt="NEUTROGENA Visibly Clear emulze grapefruit 200ml"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> VISIBLY CLEAR<sup>®</sup> Pink
                        Grapefruit Mycí emulze
                      </p>
                    </span>
                    <span className="button">Podrobnosti</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bluebar">
          <div className="bar">
            <div className="wrapper">
              <a href="/neutrogena/o-nas/">
                O ZNAČCE NEUTROGENA<sup>®</sup>
              </a>
              <span>|</span>
              <a href="/neutrogena/norska-receptura/">Norská receptura</a>
              <span>|</span>
              <a href="/neutrogena/historie/">Seznamte se s naší historií</a>
              <span>|</span>
              <a href="/produkty/">
                Zobrazit produkty NEUTROGENA<sup>®</sup>
              </a>
            </div>
          </div>
        </div>
        <Footer></Footer>
        <script
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function () {\n$('a').each(function () {\nvar linkhtml = $(this).html();\nif (linkhtml.indexOf('®') > -1 && linkhtml.indexOf('<sup>®</sup>') == -1) {\n$(this).html(linkhtml.replace(\"®\", \"<sup>®</sup>\"));\n}\n});\n});\n"
          }}
        />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function () {\n$('a').each(function () {\nvar linkhtml = $(this).html();\nif (linkhtml.indexOf('®') > -1 && linkhtml.indexOf('<sup>®</sup>') == -1) {\n$(this).html(linkhtml.replace(\"®\", \"<sup>®</sup>\"));\n}\n});\n});\n"
          }}
        />
        
      </div>
    );
  }
}

export default Page;
